.tabs {
    position: relative;
    contain: paint;
    min-width: 0;
    font-size: 12px;

    --tabs__tab-text-color: var(--rio_color_text_neutral);
    --tabs__tab-icon-color: var(--rio_color_icon_neutral);
    --tabs__tab-border-block-end-color: var(--rio_color_border_neutral_transparent);
    --tabs__tab-background-color: var(--rio_color_background_neutral_transparent);
    --tabs__sticky-block-start: var(--rio-global__sticky-block-start);
}

.tabs--content-padding-variant_none {
    --tabs-content-padding: 0;
}

.tabs--content-padding-variant_small {
    --tabs-content-padding: 8px;
}

.tabs--content-padding-variant_medium {
    --tabs-content-padding: 16px;
}

.tabs--content-padding-variant_large {
    --tabs-content-padding: 24px;
}

.tabs-labels-tab:not(.tabs-labels-tab--is_selected):not(.tabs-labels-tab--disabled):hover {
    --tabs__tab-border-block-end-color: var(--rio_color_border_interactive_hover);
    --tabs__tab-icon-color: var(--rio_color_icon_interactive_hover);
    --tabs__tab-background-color: var(--rio_color_background_interactive_softest_hover);
}

.tabs-labels-tab:not(.tabs-labels-tab--is_selected):not(.tabs-labels-tab--disabled):active {
    --tabs__tab-border-block-end-color: var(--rio_color_border_interactive_pressed);
    --tabs__tab-icon-color: var(--rio_color_icon_interactive_pressed);
    --tabs__tab-background-color: var(--rio_color_background_interactive_softest_pressed);
}

.tabs-labels-tab--is_selected {
    cursor: default;

    --tabs__tab-border-block-end-color: var(--rio_color_border_interactive_selected);
    --tabs__tab-text-color: var(--rio_color_text_interactive_selected);
    --tabs__tab-icon-color: var(--rio_color_icon_interactive_selected);
}

.tabs--display-variant_page .tabs-labels-tab--is_selected {
    --tabs__tab-background-color: var(--rio_color_background_interactive_softest_selected);
}

.tabs-labels-tab--disabled {
    cursor: not-allowed;

    --tabs__tab-text-color: var(--rio_color_text_neutral_disabled);
}

.tabs-labels-tab--disabled.tabs-labels-tab--is_selected {
    --tabs__tab-border-block-end-color: var(--rio_color_border_neutral_disabled);
    --tabs__tab-icon-color: var(--rio_color_icon_neutral_disabled);
    --tabs__tab-background-color: var(--rio_color_background_neutral_disabled);
}

.tabs:not(:last-child) {
    margin-bottom: 20px;
}

.tabs-labels {
    position: sticky;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    white-space: nowrap;
    height: 32px;
    inset-block-start: var(--tabs__sticky-block-start);
    background: var(--rio_color_background_neutral);
    border-block-end: 1px solid var(--rio_color_border_neutral);
    z-index: 1;

    --tabs-label-display: flex;
}

.tabs-labels--no_labels {
    --tabs-label-display: none;
}

.tabs-labels--is_shadow {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    inset-block-start: 0;
}

.tabs-content {
    position: relative;
    display: none;
    padding-top: var(--tabs-content-padding);
    z-index: 0;

    --rio-global__sticky-block-start: calc(var(--tabs__sticky-block-start) + 32px);
}

.tabs-content--active {
    display: block;
}

.tabs-labels-tab {
    display: flex;
    position: relative;
    flex: 0 0 auto;
    align-items: center;
    gap: 4px;
    height: 100%;
    padding-inline: 12px;
    white-space: nowrap;
    border-start-start-radius: 4px;
    border-start-end-radius: 4px;
    background: var(--tabs__tab-background-color);
    border: none;
    cursor: pointer;
    margin-top: 1px;
    border-block-end: 2px solid var(--tabs__tab-border-block-end-color);
}

.tabs-labels-tab::-moz-focus-inner {
    border: 0;
}

.tabs-labels-tab::after {
    inset-inline: 2px;
}

.tabs-labels-tab-icon {
    position: relative;
    display: block;
    font-weight: 500;
    flex: 0 0 auto;
    width: 16px;
    font-size: 16px;
    text-align: center;
    color: var(--tabs__tab-icon-color);
}

.tabs-labels-tab-label {
    position: relative;
    display: var(--tabs-label-display);
    align-items: center;
    font-weight: 500;
    flex: 1 0 auto;
    min-width: 16px;
    border-inline-start-width: 1px;
    border-block-start-width: 1px;
    color: var(--tabs__tab-text-color);
}

.tabs-labels-tab-label--no_icon {
    display: flex !important;
}

.tabs-labels-tab-label > span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media print {
    .tabs--no_print {
        display: none;
    }

    .tabs--no_tabs-list-print .tabs-labels {
        display: none;
    }

    .tabs--no_tabs-list-print .tabs-content {
        padding-top: 0;
    }
}
