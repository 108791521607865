.rio-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: max-content;
    min-width: 1em;
    height: 1em;
    flex: 0 0 auto;
    font-size: var(--rio-badge__size);
}

.rio-badge--is_filled.rio-badge--shape_circle {
    background-color: var(--rio-badge__circle-color);
}

.rio-badge--is_stroke.rio-badge--shape_circle {
    background-color: var(--rio_color_background_neutral);
    border: max(1.5px, 0.0725em) solid var(--rio-badge__circle-color);
}

.rio-badge--shape_circle {
    border-radius: 1000px;
}

.rio-badge__shape {
    position: absolute;
    inset: -12%;
    width: 124%;
    height: 124%;
}

.rio-badge__content {
    display: flex;
    font-weight: 500;
    font-size: 0.8em;
    color: var(--rio-badge__content-color);
    transform: translateY(var(--rio-badge__content-translate-y));
}

.rio-badge__content--with_icon-and-children {
    padding-inline: 0.125em 0.25em;
}

.rio-badge__children--no_icon {
    padding-block-start: 0.04em;
    padding-inline: 0.25em;
}